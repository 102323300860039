<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('MAILS')}}</h1>
    <div class="flex">
      <p>Select Status</p>
      <select v-model = "statusFilter" @change="onStatusFilterChange" class="flex-items2">
        <option value=1>ACTIVE</option>
        <option value=0>EXPIRED</option>
      </select>
    </div>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('TITLE') }}</div>
          <div class="cell">{{ $t('ID') }}</div>
          <div class="cell">{{ "Delivery Date" }}</div>
          <div class="cell">{{ $t('EXPIRATION_DATE') }}</div>
          <div class="cell"><a href="/mails/add" class="success">{{ $t('ADD') }}</a></div>
        </div>
        <div class="table-row" v-for="(mail, index) in mailsArr.filter(x => x.type === 2)" v-bind:key="mail.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ mail.title }}</div>
          <div class="cell">{{ mail.id }}</div>
          <div class="cell">{{ moment(mail.creation_date).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell">{{ moment(mail.expiration_date).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell action-cell">
            <a :href="'/mails/add?id=' + mail.id" class="info">{{ $t('EDIT') }}</a>
          </div>
        </div>
        <hr />
        <div class="table-row" v-for="(mail, index) in mailsDisplayArr" v-bind:key="mail.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ mail.title }}</div>
          <div class="cell">{{ mail.id }}</div>
          <div class="cell">{{ moment(mail.creation_date).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell">{{ moment(mail.expiration_date).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell action-cell">
            <a :href="'/mails/add?id=' + mail.id" class="info">{{ $t('EDIT') }}</a>
            <button @click="() => deleteMail(mail.id)" class="error">{{ $t('REMOVE') }}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { watch, ref, onBeforeMount, onUpdated, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import dispatchMap from "@/constants/dispatchMap";

export default {
  name: "Mails",
  setup() {
    const store = useStore();
    const usedPages = ["mails"];
    onBeforeMount(() => usedPages.forEach(page => {
      dispatchMap[page].forEach(dispatchStr => store.dispatch(dispatchStr));
    }));
    const mailsArr = ref([]);
    const statusFilter = ref(1);
    const mailsDisplayArr = ref([]);
    
    watch(() => store.getters['mails/getMails'], value => {
      mailsArr.value = [ ...value ].sort((a,b) => b.creation_date - a.creation_date)
      mailsDisplayArr.value = JSON.parse(JSON.stringify(mailsArr.value.filter(x => x.type !== 2 && x.expiration_date >= Date.now())))
    }, { immediate: true })

    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    
    const onStatusFilterChange = () => {
      mailsDisplayArr.value = JSON.parse(JSON.stringify(mailsArr.value.filter(x => x.type !== 2).filter(x => {
        if (statusFilter.value == 1)
          return x.expiration_date >= Date.now()
        else 
          return x.expiration_date < Date.now()
      })))
    }

    const deleteMail = id => {
      store.dispatch('mails/deleteMail', { id })
    }
    return {
      mailsArr,
      moment,
      deleteMail,
      mailsDisplayArr,
      statusFilter,
      onStatusFilterChange
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 2fr 2fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  display: grid;
  padding: 0 10px;
  align-items: center;
}


button, a {
  font-size: 0.9rem;
  font-weight: 400;
  display: block;
  text-decoration: none;
  color: black;
  align-self: stretch;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.cell.action-cell {
  display: flex;
}
.flex {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-around;
  align-items: center;
}
.flex-items {
  width: 150px;
  height:30px;
  margin: 5px;
  text-align: center;
}
.flex-items2 {
  width: 150px;
  height:35px;
  margin: 5px;
  text-align: center;
}
</style>